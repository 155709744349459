.progress {
  contain: content;
  overflow: hidden;
  transition: trn(transform);
  top: 0;
  position: sticky;
  z-index: 10;

  &.previewMode {
    top: rem(32);
  }

  &.previewMode.scrolledUp {
    top: rem(-8);
  }

  &.scrolledUp {
    transform: translateY(rem(40));
  }

  @media bp(xl) {
    &.scrolledUp {
      transform: translateY(rem(60));
    }
  }
}

.dealbar {
  &.previewMode {
    top: rem(32);
  }

  &.hasReadingProgress {
    top: rem(4);

    &.previewMode {
      top: rem(36);
    }
  }

  &.scrolledUp {
    transform: translateY(rem(40));

    @media bp(xl) {
      transform: translateY(rem(60));
    }
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.underDealBar {
  top: 0;
  left: 0;
  width: 100vw;
  position: fixed;
  z-index: 10;
  transition: trn(opacity, transform, background-color);

  &.previewMode {
    top: rem(32);
  }

  &.hasReadingProgress {
    top: rem(4);

    &.previewMode {
      top: rem(36);
    }
  }

  &.focused {
    .panel,
    .table {
      background-color: clr(lightable-b);
    }
  }

  .inner {
    min-width: 0;

    @media bp(lg) {
      display: flex;
      column-gap: rem(30);
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      .panel {
        flex: 1 1;
        min-width: 0;
      }

      .table {
        flex: 0 0 rem(300);
      }
    }
  }

  .panel {
    color: clr(cw);
    margin-left: rem(-15);
    margin-right: rem(-15);
    background-color: clr(lightable-b);
    transition: trn(opacity, transform, background-color);

    @media bp(lg) {
      margin-right: 0;
      min-height: rem(80);
    }

    @media bp(xl) {
      width: auto;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
      transform: translateY(100%);
    }
  }

  .table {
    margin: auto rem(-15);
    background-color: clr(lightable-b);
    border-radius: 0 0 rem(8) rem(8);
    transition: trn(opacity, transform, background-color, height);

    @media bp(lg) {
      margin-right: 0;
      margin-left: auto;
      background-color: clr(lightable-b);
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    &::after {
      content: '';
      top: 0;
      left: rem(10);
      right: rem(10);
      height: rem(1);
      position: absolute;
      background-color: clr(cw/15);

      @media bp(lg) {
        display: none;
      }
    }
  }

  .panel:not(.hidden) + .table.hidden {
    opacity: 1;
  }

  .panel.hidden + .table {
    opacity: 0;
    pointer-events: none;
    transform: translateY(100%);

    @media bp(lg) {
      max-height: rem(80);
    }
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
    transform: translateY(100%);
  }
}
